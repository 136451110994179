<template>
  <div class="page-container">

    <!--头部信息-->
    <div class="title-info">
      <!--标题-->
      <div v-if="!asSelect" class="main-title">
        题库名称：{{ questionBank['name'] }}
      </div>
      <!--其他信息-->
      <!--      <div class="flex flex-start">-->
      <!--        <span>类型:{{enums.questionBankType[questionBank['questionBankType']]}}</span>-->
      <!--        <span>专业:{{}}</span>-->
      <!--        <span>科目:{{}}</span>-->
      <!--        <span>总题数:{{}}</span>-->
      <!--      </div>-->
    </div>
    <!--筛选-->
    <div class="clearfix">
      <list-search-filter
          :search-filter="lists.searchFilter"
          @clickSearchFilterBtn="query=>ListMethods().clickSearchFilterBtn(query)"
      >
        <!--  操作  -->
        <div v-if="!asSelect&&canEdit" slot="right-container" style="margin-bottom: 10px;">
          <el-button class="el-button" type="success" @click="ListMethods().clickAddQuestion()">新增试题</el-button>
<!--          <el-button class="el-button" v-loading="importQuestionLoading" type="success"-->
<!--                     @click="ListMethods().clickImportBtn()">导入试题-->
<!--          </el-button>-->
          <!--          <el-button class="el-button" type="success" @click="ListMethods().clickImportModel()">下载试题导入模板</el-button>-->
        </div>
      </list-search-filter>
    </div>
    <!--试题类型分组-->
    <el-tabs
        v-model="chooseQuestionType"
        type="border-card"
        @tab-click="(tab,event)=>ListMethods().clickListTab(tab,event)"
    >
      <el-tab-pane label="单项选择" name="Radio">
        <!--列表-->
        <el-table
            v-loading="lists.list[0].loading"
            :data="lists.list[0].list"
            element-loading-text="加载中"
            border
            fit
            highlight-current-row
            :height="asSelect?'400':''"
            style="width: 100%;"
            @selection-change="list=>ListMethods().listSelectChange(list,0)"
        >
          <el-table-column
              v-if="asSelect"
              align="center"
              type="selection"
              width="40"
          />
          <!--          <el-table-column label="编号" align="center" width="100px">-->
          <!--            <template slot-scope="scope">-->
          <!--              <span>{{ scope.row.id }}</span>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <el-table-column label="编号" align="center" width="100px">
            <template slot-scope="scope">
              <span>{{ scope.row.id }}</span>
            </template>
          </el-table-column>
          <el-table-column label="问题描述" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.contentType==='html'" class="content-vhtml" v-html="scope.row.content"/>
              <div v-else>{{ scope.row.content }}</div>
            </template>
          </el-table-column>
          <!--渲染选项列表-->
          <el-table-column label="问题选项(蓝色为正确选项)" align="center">
            <template slot-scope="scope">
              <div
                  v-for="item in scope.row.options"
                  :label="item.choice"
                  align="center"
                  :class="item.choice===scope.row.standardAnswer?'markRight':''"
              >
                <span v-if="item.content">{{ item.choice + ':' + item.content }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              v-if="!asSelect&&canEdit"
              align="center"
              label="操作"
              width="150"
              fixed="right"
              class-name="small-padding fixed-width"
          >
            <template slot-scope="scope">
              <el-button type="primary" size="mini" round @click="ListMethods().clickEditBtn(0,scope.row,scope.$index)">
                编辑
              </el-button>
              <el-button
                  type="danger"
                  size="mini"
                  round
                  @click="ListMethods().clickDelBtn(0,scope.row.id,scope.$index)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--列表分页-->
        <div class="pagination-container">
          <el-pagination
              background
              :current-page="lists.list[0].pages.page"
              :page-size="lists.list[0].pages.limit"
              layout="total,prev, pager, next,sizes"
              :total="lists.list[0].pages.totalNumber"
              :page-count="lists.list[0].pages.totalPage"
              @current-change="(number)=>ListMethods().pageChange(0,number)"
              @size-change="(size)=>ListMethods().pageLimitChange(0,size)"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane label="多项选择" name="Multiple">
        <!--列表-->
        <el-table
            v-loading="lists.list[1].loading"
            :data="lists.list[1].list"
            element-loading-text="加载中"
            border
            fit
            highlight-current-row
            :height="asSelect?'400':''"
            style="width: 100%;"
            @selection-change="list=>ListMethods().listSelectChange(list,1)"
        >
          <el-table-column
              v-if="asSelect"
              align="center"
              type="selection"
              width="40"
          />
          <el-table-column label="编号" align="center" width="100px">
            <template slot-scope="scope">
              <span>{{ scope.row.id }}</span>
            </template>
          </el-table-column>
          <el-table-column label="问题描述" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.contentType==='html'" class="content-vhtml" v-html="scope.row.content"/>
              <div v-else>{{ scope.row.content }}</div>
            </template>
          </el-table-column>
          <!--渲染选项列表-->
          <el-table-column label="问题选项(蓝色为正确选项)" align="center">
            <template slot-scope="scope">
              <div
                  v-for="item in scope.row.options"
                  :label="item.choice"
                  align="center"
                  :class="scope.row.standardAnswers.includes(item.choice)?'markRight':''"
              >
                <span v-if="item.content">{{ item.choice + ':' + item.content }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              v-if="!asSelect&&canEdit"
              align="center"
              label="操作"
              width="150"
              fixed="right"
              class-name="small-padding fixed-width"
          >
            <template slot-scope="scope">
              <el-button type="primary" size="mini" round @click="ListMethods().clickEditBtn(1,scope.row,scope.$index)">
                编辑
              </el-button>
              <el-button
                  type="danger"
                  size="mini"
                  round
                  @click="ListMethods().clickDelBtn(1,scope.row.id,scope.$index)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--列表分页-->
        <div class="pagination-container">
          <el-pagination
              background
              :current-page="lists.list[1].pages.page"
              :page-size="lists.list[1].pages.limit"
              layout="total,prev, pager, next,sizes"
              :total="lists.list[1].pages.totalNumber"
              :page-count="lists.list[1].pages.totalPage"
              @current-change="(number)=>ListMethods().pageChange(1,number)"
              @size-change="(size)=>ListMethods().pageLimitChange(1,size)"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane label="判断题" name="Judge">
        <!--列表-->
        <el-table
            v-loading="lists.list[2].loading"
            :data="lists.list[2].list"
            element-loading-text="加载中"
            border
            fit
            highlight-current-row
            :height="asSelect?'400':''"
            style="width: 100%;"
            @selection-change="list=>ListMethods().listSelectChange(list,2)"
        >
          <el-table-column
              v-if="asSelect"
              align="center"
              type="selection"
              width="40"
          />
          <el-table-column label="编号" align="center" width="100px">
            <template slot-scope="scope">
              <span>{{ scope.row.id }}</span>
            </template>
          </el-table-column>
          <el-table-column label="问题描述" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.contentType==='html'" class="content-vhtml" v-html="scope.row.content"/>
              <div v-else>{{ scope.row.content }}</div>
            </template>
          </el-table-column>
          <!--渲染选项列表-->
          <el-table-column label="参考答案" align="center">
            <template slot-scope="scope">
              {{ enums.questionJudgeAnswer[scope.row.standardAnswer] }}
            </template>
          </el-table-column>
          <el-table-column
              v-if="!asSelect&&canEdit"
              align="center"
              label="操作"
              width="150"
              fixed="right"
              class-name="small-padding fixed-width"
          >
            <template slot-scope="scope">
              <el-button type="primary" size="mini" round @click="ListMethods().clickEditBtn(2,scope.row,scope.$index)">
                编辑
              </el-button>
              <el-button
                  type="danger"
                  size="mini"
                  round
                  @click="ListMethods().clickDelBtn(2,scope.row.id,scope.$index)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--列表分页-->
        <div class="pagination-container">
          <el-pagination
              background
              :current-page="lists.list[2].pages.page"
              :page-size="lists.list[2].pages.limit"
              layout="total,prev, pager, next,sizes"
              :total="lists.list[2].pages.totalNumber"
              :page-count="lists.list[2].pages.totalPage"
              @current-change="(number)=>ListMethods().pageChange(2,number)"
              @size-change="(size)=>ListMethods().pageLimitChange(2,size)"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
    <!--试题详情编辑-->
    <div class="edit-container">
      <!--单选题编辑-->
      <el-dialog
          v-el-drag-dialog
          title="编辑试题"
          :visible.sync="detail.dialog"
          width="60%"
          center
          @closed="DetailMethods().closeDialog()"
      >
        <div class="dialog-container">
          <el-form ref="editForm" label-width="90px" :rules="detail.formRules" :model="detail.edit">
            <el-form-item v-if="detail.type==='new'" label="试题类型:" prop="questionType">
              <el-select
                  v-model="detail.edit.questionType"
                  placeholder="请选择试题类型"
                  @change="v=>DetailMethods().questionTypeChange(v)"
              >
                <el-option key="Radio" value="Radio" label="单项选择"/>
                <el-option key="Multiple" value="Multiple" label="多项选择"/>
                <el-option key="Judge" value="Judge" label="判断题"/>
              </el-select>
            </el-form-item>
            <el-form-item label="试题内容:" prop="content">
              <el-tabs v-model="detail.edit.contentType">
                <el-tab-pane label="纯文本" name="text">
                  <el-input v-model="detail.edit.contentText" type="textarea"/>
                </el-tab-pane>
                <el-tab-pane label="富文本" name="html">
                  <tinymce width="99%"
                      ref="tinymce_content"
                      v-model="detail.edit.contentHtml"
                      :height="200"
                  />
                </el-tab-pane>
              </el-tabs>
            </el-form-item>
            <!--根据试题类型不同-->
            <el-form-item label="设置答案:" prop="answer">
              <!--单项选择-->
              <div v-if="detail.edit.questionType==='Radio'">
                <div class="edit-choose-item">
                  <el-input v-if="detail.edit.options[0]" v-model="detail.edit.options[0].content"
                            placeholder="请输入内容">
                    <template slot="prepend">
                      <el-radio v-model="detail.edit.standardAnswer" label="A"/>
                    </template>
                  </el-input>
                </div>
                <div class="edit-choose-item">
                  <el-input v-if="detail.edit.options[1]" v-model="detail.edit.options[1].content"
                            placeholder="请输入内容">
                    <template slot="prepend">
                      <el-radio v-model="detail.edit.standardAnswer" label="B"/>
                    </template>
                  </el-input>
                </div>
                <div class="edit-choose-item">
                  <el-input v-if="detail.edit.options[2]" v-model="detail.edit.options[2].content"
                            placeholder="请输入内容">
                    <template slot="prepend">
                      <el-radio v-model="detail.edit.standardAnswer" label="C"/>
                    </template>
                  </el-input>
                </div>
                <div class="edit-choose-item">
                  <el-input v-if="detail.edit.options[3]" v-model="detail.edit.options[3].content"
                            placeholder="请输入内容">
                    <template slot="prepend">
                      <el-radio v-model="detail.edit.standardAnswer" label="D"/>
                    </template>
                  </el-input>
                </div>
                <div class="edit-choose-item">
                  <el-input v-if="detail.edit.options[4]" v-model="detail.edit.options[4].content"
                            placeholder="请输入内容">
                    <template slot="prepend">
                      <el-radio v-model="detail.edit.standardAnswer" label="E"/>
                    </template>
                  </el-input>
                </div>
                <div class="edit-choose-item">
                  <el-input v-if="detail.edit.options[5]" v-model="detail.edit.options[5].content"
                            placeholder="请输入内容">
                    <template slot="prepend">
                      <el-radio v-model="detail.edit.standardAnswer" label="F"/>
                    </template>
                  </el-input>
                </div>
              </div>
              <!--多项选择-->
              <div v-if="detail.edit.questionType==='Multiple'">
                <div class="edit-choose-item">
                  <el-input v-if="detail.edit.options[0]" v-model="detail.edit.options[0].content"
                            placeholder="请输入内容">
                    <template slot="prepend">
                      <el-checkbox v-model="detail.edit.standardAnswers" label="A"/>
                    </template>
                  </el-input>
                </div>
                <div class="edit-choose-item">
                  <el-input v-if="detail.edit.options[1]" v-model="detail.edit.options[1].content"
                            placeholder="请输入内容">
                    <template slot="prepend">
                      <el-checkbox v-model="detail.edit.standardAnswers" label="B"/>
                    </template>
                  </el-input>
                </div>
                <div class="edit-choose-item">
                  <el-input v-if="detail.edit.options[2]" v-model="detail.edit.options[2].content"
                            placeholder="请输入内容">
                    <template slot="prepend">
                      <el-checkbox v-model="detail.edit.standardAnswers" label="C"/>
                    </template>
                  </el-input>
                </div>
                <div class="edit-choose-item">
                  <el-input v-if="detail.edit.options[3]" v-model="detail.edit.options[3].content"
                            placeholder="请输入内容">
                    <template slot="prepend">
                      <el-checkbox v-model="detail.edit.standardAnswers" label="D"/>
                    </template>
                  </el-input>
                </div>
                <div class="edit-choose-item">
                  <el-input v-if="detail.edit.options[4]" v-model="detail.edit.options[4].content"
                            placeholder="请输入内容">
                    <template slot="prepend">
                      <el-checkbox v-model="detail.edit.standardAnswers" label="E"/>
                    </template>
                  </el-input>
                </div>
                <div class="edit-choose-item">
                  <el-input v-if="detail.edit.options[5]" v-model="detail.edit.options[5].content"
                            placeholder="请输入内容">
                    <template slot="prepend">
                      <el-checkbox v-model="detail.edit.standardAnswers" label="F"/>
                    </template>
                  </el-input>
                </div>
              </div>
              <!--判断题-->
              <div v-if="detail.edit.questionType==='Judge'">
                <div class="edit-choose-item">
                  <el-radio v-model="detail.edit.standardAnswer" label="true">正确
                  </el-radio>
                </div>
                <div class="edit-choose-item">
                  <el-radio v-model="detail.edit.standardAnswer" label="false">错误
                  </el-radio>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
              v-if="detail.type==='edit'"
              type="primary"
              @click="DetailMethods().clickEditBtn()"
          >修 改</el-button>
          <el-button v-if="detail.type==='edit'" type="danger"
                     @click="DetailMethods().clickDeleteBtn()">删 除</el-button>
          <el-button v-if="detail.type==='new'" type="success" @click="DetailMethods().clickAddBtn()">新 增</el-button>
        </span>
      </el-dialog>
    </div>
    <!--试题导入input-->
    <input
        id="importFile"
        type="file"
        style="display: none"
        @change="(files)=>{ListMethods().importFileChange(files)}"
    >
  </div>
</template>

<script>
/**
 */
import {QuestionModel} from '@/model/exp/QuestionModel'
import ListSearchFilter from '../../components/list/listSearchFilter'
import {CommonModel} from '@/model/CommonModel'
import elDragDialog from '@/directive/el-drag-dialog'
import {msg_confirm, msg_confirm_choose, msg_err, msg_input, msg_success} from '@/utils/ele_component'
import {find_obj_from_arr_by_id, objectToLVArr, htmlAppend, arrToLVArr, isArrHasRepeat} from '@/utils/common'
// import {SubjectModel} from '@/model/SubjectModel'
import Tinymce from "@/components/Tinymce";
import {mapState} from 'vuex'
import {MessageBox} from 'element-ui'
// import {UserModel} from "@/model/UserModel";
import {EnumsModel} from "@/model/EnumsModel";
import {QuestionBankModel} from "@/model/exp/QuestionBankModel";

export default {
  name: 'QuestionList',
  components: {ListSearchFilter, Tinymce},
  directives: {
    elDragDialog
  },
  watch: {
    'detail.edit.contentType'(n, o) {
      if (n === 'html') {
        this.$set(this.detail.edit, 'content', this.detail.edit.contentHtml)
      } else {
        this.$set(this.detail.edit, 'content', this.detail.edit.contentText)
      }
    },
  },
  computed: {
    ...mapState({
      userInfo: state => state.user
    }),
  },
  props: {
    // 是否作为选择模式
    asSelect: {
      type: Boolean,
      required: false,
      default: false
    },
    // 所属的实验-选择模式用
    experimentId: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    // 检测编辑试题是否设置答案
    const validateAnswer = (rule, value, callback) => {
      switch (this.detail.edit.questionType) {
        case 'Radio':
          if (!this.detail.edit.standardAnswer) {
            callback(new Error('请设置试题答案'))
          }
          break
        case 'Multiple':
          if (this.detail.edit.standardAnswers.length === 0) {
            callback(new Error('请设置试题答案'))
          }
          break
        case 'Judge':
          if (!this.detail.edit.standardAnswer) {
            callback(new Error('请设置试题答案'))
          }
          break
        default:
          break
      }
      callback()
    }
    const validateContent = (rule, value, callback) => {
      if (this.detail.edit.contentType === 'text') {
        if (!this.detail.edit.contentText) {
          callback(new Error('请输入试题内容'))
        }
      } else {
        if (!this.detail.edit.contentHtml) {
          callback(new Error('请输入试题内容'))
        }
      }
      callback()
    }
    return {
      // 外部方法
      find_obj_from_arr_by_id: find_obj_from_arr_by_id,
      objectToLVArr: objectToLVArr,
      arrToLVArr: arrToLVArr,
      // 枚举
      enums: {
        questionJudgeAnswer: EnumsModel.questionJudgeAnswer,
        questionOptionLabel: EnumsModel.questionOptionLabel,
        questionBankType: EnumsModel.questionBankType
      },
      // 题库id
      questionBankId: this.$route.query['bankId'],
      chooseQuestionType: 'Radio', // 题型选择tab框
      // tab显示顺序
      tabOrders: ['Radio', 'Multiple', 'Judge'],
      // 列表
      lists: {
        list: [
          { // 单选题
            list: [],
            loading: false,
            query: {
              questionType: 'Radio'
            },
            queryOri: {
              questionType: 'Radio'
            },
            pages: {
              limit: 10
            },
            selectList: []
          },
          { // 多选题
            list: [],
            loading: false,
            query: {
              questionType: 'Multiple'
            },
            queryOri: {
              questionType: 'Multiple'
            },
            pages: {
              limit: 10
            },
            selectList: []
          },
          { // 判断题
            list: [],
            loading: false,
            query: {
              questionType: 'Judge'
            },
            queryOri: {
              questionType: 'Judge'
            },
            pages: {
              limit: 10
            },
            selectList: []
          },
        ],
        searchFilter: {
          search: [
            {
              type: 'input',
              label: '问题描述',
              key: 'content',
              value: ''
            },
          ],
          filter: [
            {
              type: 'select',
              label: '选择题库',
              key: 'questionBankId',
              value: '',
              data: [],
              hidden: false,
              dataObject: {},
              dataOrigin: {}, // 存储数据库返回的默认列表
              change: function (value) {

              }
            }
          ]
        }
      },
      // 详情弹窗
      detail: {
        dialog: false,
        type: 'edit', // 编辑模式、新增模式
        edit: {
          contentType: 'text',
          standardAnswers: []
        },
        // 输入检测
        formRules: {
          'questionType': {required: true, message: '请选择试题类型', trigger: 'blur'},
          'content': {required: true, message: '请输入试题内容', validator: validateContent},
          'answer': {required: true, message: '请设置试题答案', validator: validateAnswer}
        }
      },
      // 题库详情
      questionBank: {},
      subject: {}, // 题库所属科目详情
      // 用户是否有权限编辑此题库
      canEdit: true,
      importQuestionLoading: false,
    }
  },
  async mounted() {
    this.getBankInfo()
    // 获取列表-单项选择列表
    this.ListMethods().getList(0, 1, this.lists.list[0].pages.limit, this.lists.list[0].query)
    // 初始化筛选
    this.ListMethods().initFilter()
  },
  methods: {
    // 获取题库详情
    async getBankInfo() {
      if (this.questionBankId) {
        const data = await QuestionBankModel.getOne(this.questionBankId)
        if (data) {
          this.questionBank = data
          // todo 判断当前用户的编辑权限
          this.canEdit = true
        } else {
          msg_err('未找到该题库信息')
        }
      }
    },
    // 列表Methods
    ListMethods() {
      const $this = this
      return {
        // 获取列表
        async getList(index, page, limit, query) {
          // 如果不是选择模式就隐藏筛选的题库选择
          if (!$this.asSelect) {
            $this.$set($this.lists.searchFilter.filter[0], 'hidden', true)
          }
          $this.lists.list[index].loading = true
          // 如果是选择模式就合并科目属性
          if ($this.asSelect && $this.experimentId) {
            query['experimentId'] = $this.experimentId
          }
          // 如果有questionBankId作为路由参数
          if ($this.$route.query['bankId']) {
            query['questionBankId'] = $this.$route.query['bankId']
          }
          [$this.lists.list[index].list, $this.lists.list[index].pages] = await QuestionModel.getList(page, limit?limit:10, query)
          $this.lists.list[index].loading = false
          $this.lists.tabIndex=index;
        },
        // 分页-改变页码
        async pageChange(index, page) {
          this.getList(index, page, $this.lists.list[index].pages.limit, $this.lists.list[index].query)
        },
        // 分页-改变每页显示数量
        async pageLimitChange(index, limit) {
          this.getList(index, $this.lists.pages.list[index].page, limit, $this.lists.list[index].query)
        },
        // 初始化筛选列表
        async initFilter() {
          if ($this.asSelect && $this.experimentId) {
            // 初始化题库列表
            const [bankList] = await QuestionBankModel.getList(1, 500, {
              experimentId: $this.experimentId,
              schoolId:$this.userInfo.schoolId,
              departmentId:$this.userInfo.departmentid
            })
            console.log(bankList)
            const generateListFilter = CommonModel.generateListFilterOptions('name', 'id', bankList,true)
            $this.$set($this.lists.searchFilter.filter, 0, {
              type: 'select',
              label: '选择题库',
              key: 'questionBankId',
              value: '',
              data: generateListFilter[0],
              dataObject: generateListFilter[1],
              dataOrigin: bankList[0], // 存储数据库返回的默认列表
              change: function (value) {

              }
            })
          }
        },
        // 点击搜索按钮
        clickSearchFilterBtn(query) {
          const index = $this.tabOrders.indexOf($this.chooseQuestionType)
          $this.lists.list[index].query = Object.assign(query, $this.lists.list[index].queryOri)
          this.getList(index, 1, $this.lists.list[index].pages.limit?$this.lists.list[index].pages.limit:10, $this.lists.list[index].query)
        },
        // 点击列表的编辑按钮
        clickEditBtn(index, edit, $index) {
          const editCopy = JSON.parse(JSON.stringify(edit))
          $this.detail.dialog = true
          // 判断试题类型，填充对象
          $this.detail.edit = {}
          switch (edit.questionType) {
            case 'Radio':
              // option至少添加6个选项
              const lo = edit.options.length
              const left = 6 - lo
              for (let i = 0; i < left; i++) {
                edit.options.push({
                  choice: $this.enums.questionOptionLabel[lo + i],
                  content: ''
                })
              }
              break
            case 'Multiple':
              // option至少添加6个选项
              const lo1 = edit.options.length
              const left1 = 6 - lo1
              for (let i = 0; i < left1; i++) {
                edit.options.push({
                  choice: $this.enums.questionOptionLabel[lo1 + i],
                  content: ''
                })
              }

              break
            case 'Judge':
              break
          }
          $this.detail.edit = edit

          if ($this.detail.edit.contentType === 'text') {
            setTimeout(() => {
              $this.$refs['tinymce_content'].setContent('')
            }, 300)
            $this.$set($this.detail.edit, 'contentText', edit.content)
          } else {
            $this.$set($this.detail.edit, 'contentHtml', edit.content)
            setTimeout(() => {
              $this.$refs['tinymce_content'].setContent(edit.content)
            }, 300)
          }
          $this.detail.type = 'edit'
          $this.detail.index = index
          $this.detail.$index = $index
        },
        // 点击删除按钮
        async clickDelBtn(index, id, $index) {
          if (await msg_confirm('确认删除该试题吗？删除后不可用恢复！')) {
            if (await QuestionModel.remove(id)) {
              $this.lists.list[index].list.splice($index, 1)
              // 修改pageTool 数量-1
              $this.$set($this.lists.list[index].pages, "totalNumber", $this.lists.list[index].pages.totalNumber - 1)
              msg_success('删除成功!')
            }
          }
        },
        // 点击tab切换
        clickListTab(tab, event) {
          const index = $this.tabOrders.indexOf(tab.name)
          $this.lists.tabIndex=index
          if ($this.lists.list[index].list.length === 0) {
            this.getList(index, 1, $this.lists.list[index].pages.limit, $this.lists.list[index].query)
          }
          $this.$set($this.lists.searchFilter.search[0],"value","") // 切换tab时清空搜索content
        },
        // 点击新增试题按钮
        clickAddQuestion() {
          $this.detail.type = 'new'
          $this.detail.edit = {
            contentType: 'text',
            content: '',
            options: []
          }

          $this.detail.dialog = true
          setTimeout(() => {
            $this.$refs['tinymce_content'].setContent('')
          }, 500)
        },

        // 列表选择编号
        listSelectChange(list, index) {
          $this.lists.list[index]['selectList'] = list
        },

        // 父组件点击了确认选择了问题列表
        parentSureSelect() {
          // 遍历几个类型的试题列表，获取选择的列表
          const selectList = {}
          $this.lists.list.forEach((li, index) => {
            if (li.selectList.length > 0) {
              selectList[$this.tabOrders[index]] = li.selectList
            }
          })
          $this.$emit('onListSelected', selectList)
        },
        // 点击了试题导入模板按钮
        async clickImportModel() {
          let urls = await QuestionModel.downloadQuestionImportModel()
          msg_success('请选择允许打开弹窗窗口，以下载模板文件')
          window.open(urls[0])
          // const choose = await msg_confirm_choose('可选择excel或word模板，推荐excel模板，有更好的兼容性', '选择要下载的模板类型',
          //   'Word', 'Excel')
          // if (choose) {
          //   if (choose === 'left') {
          //     window.open(urls[1])
          //   }
          //   if (choose === 'right') {
          //     window.open(urls[0])
          //   }
          // }
        },
        // 点击了导入试题按钮
        clickImportBtn() {
          const uploader = document.getElementById('importFile')
          uploader.click()
        },
        // 导入试题文件选择
        async importFileChange(files) {
          const file = files.target.files[0]
          document.getElementById('importFile').value = ''
          msg_success('试题导入中，请耐心等候，不要刷新页面！')
          $this.importQuestionLoading = true
          if (await QuestionModel.importQuestion(file, $this.questionBank.id)) {
            msg_success('导入试题成功，请刷新页面查看新列表')
          }
          $this.importQuestionLoading = false;
        }
      }
    },
    // 详情Methods
    DetailMethods() {
      const $this = this
      return {
        // 试题类型被改变 目前只有新增时会被改变
        questionTypeChange(v) {
          switch (v) {
            case 'Radio':
              // option至少添加6个选项
              const lo = $this.detail.edit.options.length
              const left = 6 - lo
              for (let i = 0; i < left; i++) {
                $this.detail.edit.options.push({
                  choice: $this.enums.questionOptionLabel[lo + i],
                  content: ''
                })
              }
              break
            case 'Multiple':
              // option至少添加6个选项
              const lo1 = $this.detail.edit.options.length
              const left1 = 6 - lo1
              for (let i = 0; i < left1; i++) {
                $this.detail.edit.options.push({
                  choice: $this.enums.questionOptionLabel[lo1 + i],
                  content: ''
                })
              }
              $this.$set($this.detail.edit, 'standardAnswers', [])
              break
            case 'Judge':
              $this.detail.edit.options = []
              break
          }
        },
        // 点击编辑按钮
        async clickEditBtn() {
          $this.$refs['editForm'].validate(async validate => {
            if (validate) {
              if (await msg_confirm('确定要修改该试题信息吗？')) {
                if ($this.detail.edit.contentType === 'text') {
                  $this.detail.edit.content = $this.detail.edit.contentText
                } else {
                  $this.detail.edit.content = $this.detail.edit.contentHtml
                }
                let postData=JSON.parse(JSON.stringify($this.detail.edit))
                // 提交前根据类型不同的检测
                switch ($this.detail.edit.questionType){
                  case "Multiple":

                    break;
                }
                const mapAnswer = {}
                if (await QuestionModel.update($this.detail.edit)) {
                  msg_success('修改试题信息成功')
                  $this.detail.dialog = false
                  $this.detail.edit = {
                    options: []
                  }
                }
                // 提交成功后操作
                switch ($this.detail.edit.questionType) {
                  default:
                    break;
                }
                let index=$this.lists.tabIndex;
                $this.ListMethods().getList(index, $this.lists.list[index].pages.number, $this.lists.list[index].pages.size, $this.lists.list[index].query)
              }
            }
          })
        },
        // 点击删除按钮
        async clickDeleteBtn() {
          const id = $this.detail.edit.id
          const index = $this.detail.index
          const $index = $this.detail.$index
          if (await msg_confirm('确认删除该试题吗？删除后不可恢复！')) {
            if (await QuestionModel.delQuestion(id)) {
              msg_success('删除成功!')
              $this.detail.dialog = false
              $this.lists.list[index].list.splice($index, 1)
            }
          }
        },
        // 点击新增按钮
        async clickAddBtn() {
          $this.$refs['editForm'].validate(async validate => {
            if (validate) {
              if (await msg_confirm('确定要新增该试题吗?')) {
                if ($this.detail.edit.contentType === 'text') {
                  $this.detail.edit.content = $this.detail.edit.contentText
                } else {
                  $this.detail.edit.content = $this.detail.edit.contentHtml
                }
                // 提交前根据类型不同的检测
                switch ($this.detail.edit.questionType) {
                }
                // 添加题库id
                $this.detail.edit.questionBankId = $this.questionBankId
                // 添加实验id
                $this.detail.edit.experimentId=$this.questionBank.experimentId;
                // 添加创建人id
                $this.detail.edit.creatorId=$this.userInfo.userid;
                if (await QuestionModel.save($this.detail.edit)) {
                  msg_success('新增试题成功')
                  const index = $this.tabOrders.indexOf($this.chooseQuestionType)
                  $this.lists.list[index].list.push($this.detail.edit)
                  $this.$set($this.lists.list[index].pages, "totalNumber", $this.lists.list[index].pages.totalNumber + 1)
                  $this.detail.dialog = false
                  $this.detail.edit = {
                    options: []
                  }
                  // 提交成功后操作
                  switch ($this.detail.edit.questionType) {
                    case 'Match':

                      break
                  }
                }
              }
            }
          })
        },
        // 关闭修改弹窗界面时回调
        closeDialog() {

        }
      }
    },
  }
}
</script>

<style>
.match-question .el-input__inner {
  text-align: center;
}
</style>
<style lang="less" scoped>
.markRight {
  color: #2bb7ff;
}

.main-title {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  color: #444;
  margin-bottom: 5px;
}

.edit-choose-item {
  margin-bottom: 10px;
}

/*填空题 答案选项*/
.completion-answer-list {
  margin-bottom: 5px;

  > .el-button {
    margin-left: 5px;
  }
}

/*连线题 答案设置*/
.match-question {
  width: 400px;
  height: 300px;
  margin: 0 auto;
  margin-top: 10px;
  position: relative;

  .left {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
  }

  .right {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }

  .title {
    font-weight: bold;
    text-align: center;
  }

  .list {
    .li {
      text-align: center;
      cursor: pointer;
      width: 150px;
      height: 40px;
      line-height: 40px;
      margin-bottom: 10px;
      border-radius: 5px;
      position: relative;
    }
  }

  .lines {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    z-index: 1;
  }
}
</style>
